var prodcat = prodcat || {};
var site = site || {};

(function ($) {
  // @todo finalize sorting and filtering
  Drupal.behaviors.productGrid = {
    attach: function (context) {
      var $grids = $('.js-product-grid', context);
      var $carouselGrids = $grids.filter('.product-grid--carousel');
      var $carousels = $('.js-product-carousel', $carouselGrids);
      var hasQuickshop = $grids.hasClass('product-grid--quickshop');
      var $wrapper = $('.product-grid-wrapper', context);
      var $products = $('.js-product-grid-item', context);

      $products.each(function () {
        var $product = $(this).hasClass('product-brief--sku-product')
          ? $(this)
          : $(this).find('.product-brief--sku-product');

        if ($product) {
          var skuBaseId = $product.attr('data-sku-base-id')
            ? $product.attr('data-sku-base-id')
            : $product.data('sku-base-id');

          $product.trigger('product.updateRoute', [skuBaseId]);
        }
      });

      // Loop through and init the carousels.
      // Carousels might contain variations, so dynamically change the settings before constructing slick
      // @setup - new brand to adjust responsive/dots settings per designs
      $carousels.each(function () {
        var $currentCarousel = $(this);
        var arrowsDiv = $currentCarousel.parent().find('.carousel-controls');
        var dotsDiv = $currentCarousel.parent().find('.carousel-dots');
        var desktopSlidesConfig = $currentCarousel.parent().attr('data-carousel-medium');
        var desktopSlidesNum = parseInt(desktopSlidesConfig) || 3;
        var mobileSlidesConfig = $currentCarousel.parent().attr('data-carousel-small');
        var mobileSlidesNum = parseInt(mobileSlidesConfig) || 1;
        var settings = {
          appendArrows: arrowsDiv,
          infinite: true,
          appendDots: dotsDiv,
          dots: true,
          slidesToShow: desktopSlidesNum,
          slidesToScroll: desktopSlidesNum,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                appendDots: dotsDiv,
                arrows: true,
                dots: true,
                slidesToShow: mobileSlidesNum,
                slidesToScroll: mobileSlidesNum
              }
            }
          ]
        };
        // slidesToShow override
        var slidesToShowCount = $(this).data('slides-to-show');

        if (slidesToShowCount) {
          settings.slidesToShow = slidesToShowCount;
        }

        // Init this carousel with our settings
        $(this).slick(settings);

        // On before slide change
        $(this).on('beforeChange', function () {
          // Remove quickshop:
          if (hasQuickshop && Drupal.behaviors.quickshopOverlay) {
            $('.js-quickshop', $wrapper).remove();
            var $allProductCells = $('.js-product-grid-item');

            // Remove item-active class from all products.
            $allProductCells.removeClass('item-active');
          }
        });
      });

      var collectGridProductIds = function ($gridItems) {
        var gridItems = $.makeArray($gridItems);
        var allProductIds = $.map(gridItems, function (cell) {
          return $(cell).data('product-id');
        });

        return allProductIds;
      };
      var sortGrid = function (sortFn) {
        var $allProductCells = $('.js-product-grid-item');
        var allProductIds = collectGridProductIds($allProductCells);
        // Add the touts
        var $toutCells = $('.product-grid__item--tout');
        var allProductData = [];

        _.each(allProductIds, function (prodId) {
          allProductData.push(prodcat.data.getProduct(prodId));
        });
        // console.log(_.map(allProductData, "PRICE"));
        var sortedProductData = _.sortBy(allProductData, sortFn);
        // console.log(_.map(sortedProductData, "PRICE"));
        var sortedCells = [];

        _.each(sortedProductData, function (prod) {
          if (prod) {
            sortedCells.push($('.js-product-grid-item[data-product-id=' + prod.PRODUCT_ID + ']'));
          }
        });
        var $grid = $('.js-product-grid');

        $grid.empty();
        _.each(sortedCells, function (cell) {
          $grid.append($(cell));
        });
        $grid.append($toutCells);
        if (hasQuickshop && prodcat.ui.quickshop) {
          prodcat.ui.quickshop.init($grid);
        }
      };

      $(document).on('mpp_sort:selected', function (e, sortFn) {
        var $productGrid = $('.js-product-grid', context);

        sortGrid(sortFn);
        site.util.grids.equalHeightsGrids($productGrid);
      });

      var filterData = function (filterBy, val, allProductIds) {
        var filteredProductIds = [];

        filteredProductIds = _.filter(allProductIds, function (prodId) {
          var prodData = prodcat.data.getProduct(prodId);
          var vals = prodData[filterBy];

          if (!vals) {
            return null;
          }
          vals = vals.split(',');

          return _.includes(vals, val);
        });

        return filteredProductIds;
      };
      var filterGrid = function (filterBy, val) {
        var $allProductCells = $('.js-product-grid-item');

        $allProductCells.show();
        if (val.length < 1) {
          return null;
        }
        var allProductIds = $('.js-product-grid-item').map(function () {
          return $(this).data('product-id');
        });
        var filteredProductIds = filterData(filterBy, val, allProductIds);

        if (filteredProductIds.length) {
          $allProductCells
            .show()
            .not(function () {
              var $gridItem = $(this);
              var thisProductId = $gridItem.data('product-id');

              return _.includes(filteredProductIds, thisProductId);
            })
            .hide();
        }
      };

      $(document).on('mpp_filter:selected', function (e, filterBy, val) {
        filterGrid(filterBy, val);
      });
    }
  };
})(jQuery);
